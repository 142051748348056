import React from 'react'
import "./../css/Spinner.css"
export const Spinner = () => {
  return (
    <div className="wrap__Spinner">
  <div className='spinner-3'></div>
    </div>
  
  )
}
