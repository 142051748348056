import React from 'react'
import List from '../components/List'
import Text from '../components/Text'
import Image from '../components/Image';
import Rubric from '../components/Rubric';


const AboutScreen = () => {
  return (
    <div className="wrap-about">
      <div className='about base-grid color_lv_1-2'>


        <Text text={"Вот уже около 70 лет в диспансере оказывается специализированная медицинская помощь онкологическим больным. Бережно сохраняя традиции, сегодня мы используем весь арсенал – высококвалифицированные кадры, современное оборудование и лекарственные средства – для профилактики, диагностики, лечения и реабилитации больных злокачественными новообразованиями.   Постоянно развиваясь, мы оказываем медицинскую помощь на уровне мировых стандартов, предлагая широкий спектр доступных, качественных и высокотехнологичных видов медицинской помощи для пациентов в комфортных условиях. В нашем диспансере оказывается весь спектр профилактических, диагностических и лечебных  услуг. Вы сможете задать интересующие Вас вопросы."} />
        <List name=" Направления деятельности:"
          list={[
            "Профилактика злокачественных новообразований;",
            "Все виды диагностических исследований;",
            "Операции при доброкачественных и злокачественных опухолях;",
            "Современные методы лучевой терапии;",
            "Лекарственное лечение опухолей;",
            "Реконструктивно-пластическая хирургия;",
          ]} />
        <Image src="pictures/s.jpg" />




      </div>
    </div>

  )

}

export default AboutScreen