import React from 'react'

const Rubric = (props:any) => {
  return (
    <div className=' rubric'>
    <h1>{props.text}</h1>
</div>
  )
}

export default Rubric
