import React, { useRef, useState, useLayoutEffect } from "react";

import useVideoPlayer from "./hooks/useVideoPlayer";
import useVideoFullScreen from "./hooks/useVideoFullScreen";

import { ReactComponent as PlayListIcon } from "./../../asset/svg/bootstrap-icons-1.11.2/collection-play-fill.svg";

import "./../../css/VideoPlayer.css";
import useConcealment from "./hooks/useConcealment";

import {
  Controls,
  Start,
  Progress,
  Volume,
  Screen,
  Time,
  PrevBtn,
  NextBtn,
} from "./Controls";
import PlayList from "./PlayList";
type VideoList = {
  List: {
    Title: string;
    Preview: string;
    Video: string;
  }[];
};
const Video = ({ List }: VideoList) => {
  const videoElement = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoVolume,
    toggleMute,
    duration,
    videoProgress,
    toPrevVideo,
    toNextVideo,
  } = useVideoPlayer(videoElement, List, setVideoIndex, videoIndex);
  const { Title, Preview, Video } = List[videoIndex];
  const { handleFullScreen } = useVideoFullScreen();

  // const { handleDisableControl, handleMouseOver, handleMouseOut } =
  //   useConcealment();
  const [playlistActive, setPlaylistActive] = useState(false);
  return (
    <div
      className="video__container"
      id="myvideo"

    >
      <div className="wrap-control" style={{ opacity: `${1 * 100}%` }}>
        <div className="video-name ">
          <h4 className="color-player"> {Title}</h4>
          <div
            className="playlist color-player"
            onClick={() => {
              setPlaylistActive(!playlistActive);
            }}
          >
            <PlayListIcon />
            <div>Плейлист</div>
          </div>
        </div>
        <div className="wrap-playlist">
          {playlistActive ? (
            <PlayList
              VideoList={List}
              setPlaylistActive={setPlaylistActive}
              setVideoIndex={setVideoIndex}
            />
          ) : (
            <></>
          )}
        </div>
        <Controls>
          <Progress
            duration={duration}
            value={videoProgress}
            event={handleVideoProgress}
          />
          <PrevBtn event={toPrevVideo} />
          <Start start={playerState.isPlaying} event={togglePlay} />
          <NextBtn event={toNextVideo} />
          <Volume
            EventVolume={handleVideoVolume}
            value={playerState.isMuted}
            EventMute={toggleMute}
          />
          <Time CurrentTime={videoProgress} DurationTime={duration} />
          <div></div>
          <Screen value={handleFullScreen} />
        </Controls>
      </div>
      <div className="video-wrapper">
        <video
          src={window.location.origin + "/video/" + Video}
          onDoubleClick={togglePlay}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
        />
      </div>
    </div>
  );
};

export default Video;
