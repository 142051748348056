import React from 'react'
import './../css/reviews.css'

const ReviewsScreen = () => {
  return (
    <div className=' Reviews'>
        <div className="view-reviews">
          <div className="item-view color_lv_1-2">
          Выражаю огромную Благодарность хирургам урологического отделения Онкологического диспансера — Кушнир А.Н., Семеняк А.С. за их высокий профессионализм, добросовестный труд, за ваши золотые руки, мастерство, а также спасибо всему обслуживающему персоналу – медицинским сестричкам и санитарам.

Желаю Вам дальнейших профессиональных успехов и достойное вознаграждение за Ваш труд!

С уважением пациентка пал №8 п. Первомайский
          </div>
          <div className="item-view color_lv_1-2">
          Проходила обследование в Вашей поликлинике с 29.07.2016  — 12.08.2016 г очень благодарна всем врачам и младшему персоналу, с кем мне довелось общаться. За их внимание, доброе отношение — это дорогого стоит.

Здитовец Татьяна Г.
          </div>

        </div>
        <div className="leave-feedback color_lv_1-2">
        Уважаемые посетители нашего сайта! Чтобы разместить на данной странице Ваш отзыв, отправьте сообщение нам на электронный адрес info@guzkod.ru(в теме письма укажите «Благодарность»)
        </div>
    </div>
  )
}

export default ReviewsScreen