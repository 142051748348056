
import Pdf from '../components/Pdf'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ListPDF = () => {
  const history = useNavigate()

  const [posts, setPosts] = useState([]);
  let doc: string;

  let locwin = window.location.pathname;
  if ("/specialists" === locwin) {
    //перечень рекомендованных обследований
    doc = "recommendations"
  }
  if ("/working-mode" === locwin) {
    //режим работы
    doc = "working"
  }
  if ("/admission" === locwin) {
    //график приема
    doc = "schedule"
  }
  if ("/terms" === locwin) {
    // о правилах и сроках
    doc = "terms"
  }
  if ("/obligations" === locwin) {
    //права и обязаности
    doc = "obligations"
  }
  if ("/regulations" === locwin) {

    //о правилах записи
    doc = "regulations"
  }
  if ("/guarantees" === locwin) {
    //программа госгарантей
    doc = "guarantees"
  }

  if ("/licenses" === locwin) {
    //программа госгарантей
    doc = "licenses"
  }
  if ("/documents" === locwin) {
    //программа госгарантей
    doc = "documentation"
  }
  if ("/anticorruption" === locwin) {
    //программа госгарантей
    doc = "anticorruption"
  }
  if ("/extraordinary" === locwin) {
    //программа госгарантей
    doc = "extraordinary"
  }
  if ("/correction-plan" === locwin) {
    //программа госгарантей
    doc = "correction-plan"
  }
  if ("/official-information" === locwin) {
    //программа госгарантей
    doc = "official-information"
  }
  const Data: any = () => {
    if ("/anticorruption" === locwin) {
      return (
        <>
          <Pdf
            name={"Приказ №35, Об утверждении мероприятий по предупреждению и противодействию коррупции"}
            src={"anticor1.pdf"}
          />
          <Pdf
            name={"Приказ №35, Приложение №1"}
            src={"anticor2.pdf"}
          />
          <Pdf
            name={"Приказ №35, Приложение №2"}
            src={"anticor3.pdf"}
          />
          <Pdf
            name={"Приказ №35, Приложение №3"}
            src={"anticor4.pdf"}
          />
          <Pdf
            name={"Приказ №35, Приложение №4"}
            src={"anticor5.pdf"}
          />
          <Pdf
            name={"Приказ №35, Приложение №5"}
            src={"anticor.pdf"}
          />
          <Pdf
            name={"Приказ №35, Приложение №6"}
            src={"anticor6.pdf"}
          />
          <Pdf
            name={"Приложение №2 К Положению о мерах по предупреждению и противодействию коррупции"}
            src={"anticor7.pdf"} />

        </>
      )
    }
    if ("/licenses" === locwin) {
      return (
        <>
          <Pdf
            name={"Выписка из реестра лицензий на Фарм деятельность"}
            src={"licenses2.pdf"}
          />
          <Pdf
            name={"Выписка из реестра мед. деятельность"}
            src={"licenses3.pdf"} />
          <Pdf
            name={"Выписка из реестра лицензий на осуществление деятельности по обороту наркотических средств по состоянию на 05:30 06.03.2024 г"}
            src={"licenses060324.pdf"} />

        </>

      )


    }
    if ("/official-information" === locwin) {
  return(
    <Pdf
    name={"Приказ №621 ГУЗ КОД"}
    src={"byx.pdf"}
  /> 
  )
   
   
    
    }
  }

  useEffect(() => {
    fetch('/api/documents/' + doc)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [history])













  return (
    <div className='Specialists '>
      <div className="wrap-Specialists">
        {posts.map((item: any) => (
          <Pdf
            name={item.name}
            src={item.resource}
          />
        ))}

        <Data />
      </div>


    </div>
  )
}

export default ListPDF
