import React from 'react'

const Text = (props:any) => {
  return (
    <div className='text-con'>
      
  {props.text}
    </div>
  )
}

export default Text