import './../css/worker.css'

const WorkerScreen = () => {
  // const isLoggedIn 
  if (true) {
    return (
      <div className='worker__win'>
        <div className="worker_in color_lv_1-2">
          <h1>Вход</h1>
          <form >
          <input type="password" id='work_pas' placeholder='Введите пароль'/>
          <input type="submit" id='work_btn' value="Вход" />
          </form>
         
        </div>
        
      </div>
    )
  }
  return (
    <div className='worker__win'>
  qweqweeqe
  </div>
  )
}


export default WorkerScreen