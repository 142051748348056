import React from 'react'
import './SoCool.css'
type Props = {
  Image: any
  link: string
}

export const SoCool = ({Image,link}: Props) => {
  return (
    <div className='SoCool'>
<img src={Image} alt="" />
<a href={link}>
<span>Перейти</span></a>
    </div>
  )
}

 