import React from 'react'

const Subtitle = (props:any) => {
  return (
    <div className=' rubric'>
    <h2>{props.text}</h2>
</div>
  )
}

export default Subtitle